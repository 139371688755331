import React from "react";

import Select from "react-select";
import {
  NavLink,
  NavLinkProps,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useApolloClient, useQuery } from "@apollo/client";

import { useTranslation } from "react-i18next";

import PlanBlock from "../PlanBlock";
import RoleBlock from "../RoleBlock";
import ClientBlock, { ClientBlockProps } from "../ClientBlock";

import { useCurrentClient } from "../../context/Client";

import {
  QUERY_AUTH,
  QUERY_CLIENTS_FOR_SIDEBAR,
} from "../../config/graphql/query";
import { useSidebarState } from "../../context/Sidebar";

type SidebarNavLinkProps = NavLinkProps & ClientBlockProps;

const SidebarNavLink = (props: SidebarNavLinkProps) => {
  const {
    to,
    className = "",
    children,
    include,
    exclude,
    roles,
    ...rest
  } = props;

  return (
    <ClientBlock include={include} exclude={exclude} roles={roles}>
      <NavLink
        {...rest}
        to={to}
        className={`list-group-item list-group-item-action ${className}`}
      >
        {children}
      </NavLink>
    </ClientBlock>
  );
};

const LEGACY_CLIENTS_LIST = [
  "the-base",
  "columbus",
  "gebouw-144",
  "secoya",
  "jupiter",
  "flexcom",
  "toro",
  "funeral-groningen",
  "ename",
  "ffwd-demo-presentation",
  "2orrange",
  "a1-demo",
  "hapag",
];

const ClientSelect = React.memo(() => {
  const location = useLocation();
  const history = useHistory();

  const client = useApolloClient();

  const currentClient = useCurrentClient();

  const { data: clientsData } = useQuery<{ clients: IClient[] }>(
    QUERY_CLIENTS_FOR_SIDEBAR,
  );

  return (
    <div className="p-3">
      <Select
        // menuIsOpen
        // menuPortalTarget={document.body}

        value={currentClient}
        options={clientsData?.clients ?? []}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.title}
        onChange={(newValue) => {
          if (!newValue?.id) {
            return;
          }

          client.cache.writeQuery({
            query: QUERY_AUTH,
            data: {
              clientId: newValue?.id,
            },
          });

          client.clearStore();

          let rootPageUrl = location.pathname.split("/").slice(0, 2).join("/");

          if (rootPageUrl === "/toro") {
            rootPageUrl = location.pathname
              .split("/")
              .filter((_, i) => i !== 1)
              .join("/");
          }

          if (
            (rootPageUrl === "/meetings" || rootPageUrl === "/visitors") &&
            newValue.code === "toro"
          ) {
            rootPageUrl = `/toro${rootPageUrl}`;
          }

          history.push(rootPageUrl);

          window.location.reload();
        }}
      />
    </div>
  );
});

const Sidebar = () => {
  const { toggle: toggleSidebar } = useSidebarState();

  const { t } = useTranslation(["sidebar"]);

  return (
    <div
      id="sidebar-wrapper"
      className="bg-light border-right d-flex flex-column"
    >
      <nav className="navbar navbar-expand-lg navbar-light border-bottom justify-content-between">
        <div className="sidebar-heading">{t("sidebar:heading")}</div>
        <button
          className="btn primary fa fa-close outline-none"
          onClick={() => toggleSidebar()}
        />
      </nav>

      <RoleBlock roles={["ADMIN", "CLIENT_ADMIN"]}>
        <ClientSelect />
      </RoleBlock>

      <div className="list-group list-group-flush">
        {/* <NavLink
          exact
          to="/"
          className="list-group-item list-group-item-action"
        >
          {t("sidebar:link.dashboard")}
        </NavLink> */}
        <RoleBlock roles={["ADMIN", "CLIENT_ADMIN", "CLIENT", "TORO1", "USER"]}>
          <NavLink
            to="/entity"
            className="list-group-item list-group-item-action"
          >
            {t("sidebar:link.entity")}
          </NavLink>
        </RoleBlock>

        <SidebarNavLink
          to="/deceased"
          roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
          include={[
            "funeral-groningen",
            "ename",
            "ffwd-demo-presentation",
            "2orrange",
          ]}
        >
          {t("sidebar:link.deceased")}
        </SidebarNavLink>

        <SidebarNavLink
          to="/devices"
          roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
          include={["a1-demo"]}
        >
          {t("sidebar:link.devices")}
        </SidebarNavLink>

        <SidebarNavLink
          to="/courier-deliveries"
          roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
          include={["hapag"]}
        >
          {t("sidebar:link.courierDeliveries")}
        </SidebarNavLink>

        <SidebarNavLink
          to="/company"
          roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
          exclude={["funeral-groningen", "ename", "hapag"]}
        >
          {t("sidebar:link.company")}
        </SidebarNavLink>

        <SidebarNavLink
          to="/toro/meetings"
          roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
          include={["toro"]}
        >
          {t("sidebar:link.meetings")}
        </SidebarNavLink>
        <SidebarNavLink
          to="/toro/visitors"
          roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
          include={["toro"]}
        >
          {t("sidebar:link.visitorLogs")}
        </SidebarNavLink>

        <SidebarNavLink
          to="/employees"
          roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
          exclude={LEGACY_CLIENTS_LIST}
        >
          {t("sidebar:link.employees")}
        </SidebarNavLink>
        <PlanBlock exclude={["free"]}>
          <SidebarNavLink
            to="/visitors"
            roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
            exclude={LEGACY_CLIENTS_LIST}
          >
            {t("sidebar:link.visitors")}
          </SidebarNavLink>
        </PlanBlock>
        <SidebarNavLink
          to="/meetings"
          roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
          exclude={LEGACY_CLIENTS_LIST}
        >
          {t("sidebar:link.meetings")}
        </SidebarNavLink>
        <SidebarNavLink
          to="/activity"
          roles={["ADMIN", "CLIENT_ADMIN", "CLIENT", "ACTIVITY"]}
          exclude={LEGACY_CLIENTS_LIST}
        >
          {t("sidebar:link.activity")}
        </SidebarNavLink>
      </div>

      <div className="list-group list-group-flush">
        <p className="m-3 text-primary font-weight-bold small">
          {t("sidebar:section.display")}
        </p>
        <PlanBlock exclude={["free"]}>
          <SidebarNavLink
            to="/nameboards"
            roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
            include={[
              "columbus",
              "gebouw-144",
              "the-base",
              "secoya",
              "jupiter",
              "ffwd-demo-presentation",
              "default-client",
              "ffwdrs",
              "horapark",
            ]}
          >
            {t("nameboards:title")}
          </SidebarNavLink>
        </PlanBlock>
        <SidebarNavLink
          to="/digital-receptions"
          roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
          exclude={["funeral-groningen", "ename"]}
        >
          {t("digitalReception:title")}
        </SidebarNavLink>

        <SidebarNavLink
          to="/wayfinding"
          roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
          include={[
            "default-client",
            "funeral-groningen",
            "the-base",
            "zuyderland",
          ]}
        >
          {t("sidebar:link.wayfinding")}
        </SidebarNavLink>

        <SidebarNavLink
          to="/minew-device"
          roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
          include={["ename", "ffwd-demo-presentation", "2orrange", "a1-demo"]}
        >
          {t("sidebar:link.screens")}
        </SidebarNavLink>

        <SidebarNavLink
          to="/floorInfo"
          roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
          include={["the-base"]}
        >
          {t("sidebar:link.floorInfo")}
        </SidebarNavLink>

        <SidebarNavLink
          to="/postbox"
          roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
          include={["the-base"]}
        >
          {t("sidebar:link.postbox")}
        </SidebarNavLink>
      </div>

      <div className="list-group list-group-flush">
        <p className="m-3 text-primary font-weight-bold small">
          {t("sidebar:section.manage")}
        </p>
        <RoleBlock roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}>
          <SidebarNavLink
            to="/client"
            roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
          >
            {t("sidebar:link.client")}
          </SidebarNavLink>
          <SidebarNavLink
            to="/users"
            roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
          >
            {t("sidebar:link.user")}
          </SidebarNavLink>
        </RoleBlock>

        <SidebarNavLink
          to="/profile/settings"
          roles={["ADMIN", "CLIENT_ADMIN", "CLIENT", "USER"]}
        >
          {t("sidebar:link.profile")}
        </SidebarNavLink>
      </div>

      <RoleBlock roles={["ADMIN"]}>
        <div className="list-group list-group-flush">
          <p className="m-3 text-primary font-weight-bold small">
            {t("sidebar:section.admin")}
          </p>
          <NavLink
            to="/clients"
            className="list-group-item list-group-item-action"
          >
            {t("sidebar:link.clients")}
          </NavLink>
          <NavLink
            to="/visionects"
            className="list-group-item list-group-item-action"
          >
            {t("sidebar:link.visionects")}
          </NavLink>
          <NavLink
            to="/minews"
            className="list-group-item list-group-item-action"
          >
            {t("sidebar:link.minews")}
          </NavLink>
          <NavLink
            to="/logs"
            className="list-group-item list-group-item-action"
          >
            {t("sidebar:link.log")}
          </NavLink>
          <NavLink
            to="/errorLogs"
            className="list-group-item list-group-item-action"
          >
            {t("sidebar:link.errorLog")}
          </NavLink>
          <NavLink
            to="/assets"
            className="list-group-item list-group-item-action"
          >
            {t("sidebar:link.assets")}
          </NavLink>
        </div>
      </RoleBlock>
    </div>
  );
};

export default React.memo(Sidebar);
