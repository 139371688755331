import React, { useEffect, useMemo, useRef } from "react";

import dayjs from "dayjs";
import * as yup from "yup";
import { jsPDF } from "jspdf";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { Link, useHistory, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";

import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import Input from "../../../components/Input";

import {
  QUERY_COURIER_DELIVERY,
  QUERY_COURIER_DELIVERIES,
} from "../../../config/graphql/query";
import { MUTATION_CREATE_COURIER_DELIVERY } from "../../../config/graphql/mutation";

import logo from "./logo.png";
import { PoppinsNormal, PoppinsBold } from "./Poppins";

type FieldValues = Omit<ICourierDelivery, "id" | "signedAt">;

const CourierDeliveryCreate = React.memo(() => {
  const history = useHistory();

  const { t } = useTranslation(["courierDelivery", "common"]);

  const { id } = useParams<{ id: string }>();

  const schema = useMemo(() => {
    return yup.object().shape({
      courierService: yup.string(),
      recipientCompany: yup
        .string()
        .required(
          t("courierDelivery:courierDelivery.yup.recipientCompany.required"),
        ),
      documentNumber: yup
        .string()
        .required(
          t("courierDelivery:courierDelivery.yup.documentNumber.required"),
        ),
    });
  }, [t]);

  const methods = useForm<FieldValues>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
    mode: "onChange",
    defaultValues: {
      courierService: "",
      recipientCompany: "",
      documentNumber: "",
    },
  });

  const {
    data: courierDeliveryData,
    startPolling,
    stopPolling,
  } = useQuery<{
    courierDelivery: ICourierDelivery;
  }>(QUERY_COURIER_DELIVERY, {
    skip: !id,
    variables: { id },
    pollInterval: 0,
    onCompleted: ({ courierDelivery }) => {
      methods.reset(courierDelivery);
    },
  });

  const status = useMemo(
    () => courierDeliveryData?.courierDelivery.status,
    [courierDeliveryData],
  );

  const previousStatus = useRef<string | undefined>();

  const statusColor = useMemo(() => {
    if (status === "Active") {
      return "warning";
    }

    if (status === "Signed") {
      return "success";
    }

    if (status === "Unsigned") {
      return "danger";
    }

    return "";
  }, [status]);

  useEffect(() => {
    if (!status) {
      return;
    }

    if (previousStatus.current === "Active" && status === "Signed") {
      toast.success<string>(t("courierDelivery:courierDelivery.toast.signed"));
    }

    if (status === "Active") {
      startPolling(1000);
    } else {
      stopPolling();
    }

    previousStatus.current = status;
  }, [status, startPolling, stopPolling]);

  const [onCreate] = useMutation(MUTATION_CREATE_COURIER_DELIVERY, {
    refetchQueries: [
      {
        query: QUERY_COURIER_DELIVERIES,
      },
    ],
  });

  const onSubmit = (variables: FieldValues) => {
    return onCreate({ variables: { input: variables } })
      .then(({ data: { createCourierDelivery } }) => {
        toast.success<string>(
          t("courierDelivery:courierDelivery.toast.created"),
        );

        history.replace(`/courier-deliveries/${createCourierDelivery.id}`);
      })
      .catch(() => {
        toast.error<string>(
          t("courierDelivery:courierDelivery.toast.createdError"),
        );
      });
  };

  const onSavePdf = () => {
    if (!courierDeliveryData?.courierDelivery.signature) {
      return;
    }

    // eslint-disable-next-line new-cap
    const doc = new jsPDF();

    doc.addFileToVFS("Poppins.ttf", PoppinsNormal);
    doc.addFont("Poppins.ttf", "Poppins", "normal");

    doc.addFileToVFS("Poppins-Bold.ttf", PoppinsBold);
    doc.addFont("Poppins-Bold.ttf", "Poppins", "bold");

    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;

    const margin = 20;

    doc.setFont("Poppins", "bold");
    doc.setFontSize(24);
    doc.text(t("courierDelivery:courierDelivery.bl"), 82, 71);

    doc.setDrawColor("#ff6600");
    doc.setLineWidth(2.25);
    doc.line(95, 88, pageWidth - 95, 88);

    doc.setFontSize(16);

    doc.setFillColor(240, 241, 245);
    doc.rect(margin, 105, pageWidth - 2 * margin, 33, "F");

    doc.text(
      t("courierDelivery:courierDelivery.form.recipientCompany"),
      margin + 10,
      118,
    );

    doc.setFont("Poppins", "normal");
    doc.text(
      courierDeliveryData?.courierDelivery.recipientCompany,
      margin + 10,
      128,
    );

    doc.setFillColor(240, 241, 245);
    doc.rect(margin, 141, pageWidth - 2 * margin, 35, "F");

    doc.setFont("Poppins", "bold");
    doc.text(
      t("courierDelivery:courierDelivery.form.documentNumber"),
      margin + 10,
      154,
    );

    doc.setFont("Poppins", "normal");
    doc.text(
      courierDeliveryData?.courierDelivery.documentNumber,
      margin + 10,
      164,
    );

    if (!!courierDeliveryData?.courierDelivery.courierService) {
      doc.setFillColor(240, 241, 245);
      doc.rect(margin, 179, pageWidth - 2 * margin, 35, "F");

      doc.setFont("Poppins", "bold");
      doc.text(
        t("courierDelivery:courierDelivery.form.courierService"),
        margin + 10,
        192,
      );

      doc.setFont("Poppins", "normal");
      doc.text(
        courierDeliveryData?.courierDelivery.courierService,
        margin + 10,
        202,
      );
    }

    const signatureWidth = 43;
    const signatureHeight = 24;

    doc.text(
      t("courierDelivery:courierDelivery.signature"),
      pageWidth - margin,
      pageHeight - signatureHeight - margin - 10,
      { align: "right" },
    );

    doc.addImage(
      courierDeliveryData?.courierDelivery.signature,
      "PNG",
      pageWidth - signatureWidth - margin,
      pageHeight - signatureHeight - margin,
      signatureWidth,
      signatureHeight,
    );

    const img = new Image();

    img.src = logo;

    img.onload = () => {
      doc.addImage(img, "PNG", 29, 18, pageWidth - 58, 31);

      doc.save(`${t("courierDelivery:courierDelivery.bl")}.pdf`);
    };
  };

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/courier-deliveries">
              {t("courierDelivery:courierDelivery.nav.courierDelivery", {
                count: 2,
              })}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t("courierDelivery:courierDelivery.nav.courierDelivery", {
              count: 1,
            })}
          </li>
        </ol>
      </nav>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="row">
            {!id ? (
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="courierService">
                    {t("courierDelivery:courierDelivery.form.courierService")}
                  </label>
                  <Input name="courierService" className="form-control" />
                </div>
                <div className="form-group">
                  <label htmlFor="recipientCompany">
                    {t("courierDelivery:courierDelivery.form.recipientCompany")}
                  </label>
                  <Input name="recipientCompany" className="form-control" />
                </div>
                <div className="form-group">
                  <label htmlFor="documentNumber">
                    {t("courierDelivery:courierDelivery.form.documentNumber")}
                  </label>
                  <Input name="documentNumber" className="form-control" />
                </div>
              </div>
            ) : (
              <div className="col-lg-4 col-md-6 col-sm-12">
                {courierDeliveryData?.courierDelivery.courierService && (
                  <Card className="mb-3 p-3">
                    {t("courierDelivery:courierDelivery.form.courierService")}
                    {courierDeliveryData?.courierDelivery.courierService}
                  </Card>
                )}
                <Card className="mb-3 p-3">
                  {t("courierDelivery:courierDelivery.form.recipientCompany")}
                  {courierDeliveryData?.courierDelivery.recipientCompany}
                </Card>
                <Card className="mb-3 p-3">
                  {t("courierDelivery:courierDelivery.form.documentNumber")}
                  {courierDeliveryData?.courierDelivery.documentNumber}
                </Card>
                {status && (
                  <Card className={`mb-3 p-3 bg-${statusColor} text-white`}>
                    {t("courierDelivery:courierDelivery.form.status")}
                    {t(
                      `courierDelivery:courierDeliveries.td.status.${status.toLowerCase()}`,
                    )}
                  </Card>
                )}
                {!!courierDeliveryData?.courierDelivery.signedAt && (
                  <Card className="mb-3 p-3">
                    {t("courierDelivery:courierDelivery.form.signedAt")}
                    {dayjs(courierDeliveryData?.courierDelivery.signedAt)
                      .local()
                      .format("DD-MM-YYYY / HH:mm:ss")}
                  </Card>
                )}
              </div>
            )}
          </div>
          {!id && (
            <div className="row mt-3">
              <div className="col-12">
                <input type="submit" className="btn btn-primary mr-3" />
              </div>
            </div>
          )}
          {!!courierDeliveryData?.courierDelivery.signature && (
            <Button className="mb-3 py-2 px-4" onClick={onSavePdf}>
              {t("courierDelivery:courierDelivery.button.savePdf")}
            </Button>
          )}
        </form>
      </FormProvider>
    </div>
  );
});

export default CourierDeliveryCreate;
