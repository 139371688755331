import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useMutation, useQuery } from "@apollo/client";

import clsx from "clsx";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { FormProvider, useForm, Controller } from "react-hook-form";

import {
  QUERY_CLIENT,
  QUERY_CLIENT_PREFERENCES,
} from "../../../../config/graphql/query";
import { UPDATE_CLIENT_PREFERENCES } from "../../../../config/graphql/mutation";

import { PreferencesController } from "../../../../components/Preferences";

import { useClientId } from "../hooks/useClient";
import useHasRole from "../../../../lib/hooks/useHasRole";
import { useCurrentHasPlan } from "../../../../components/PlanBlock";
import { EmailPreferencesController } from "../../../../components/EmailPreferences";

type FieldValues = {
  preferences?: Omit<IClientPreferences, "autoCheckout"> & {
    autoCheckout: { enabled: boolean; time: Date };
  };
};

const ClientPreferences = React.memo(() => {
  const { t } = useTranslation(["employeePreferences"]);

  const clientId = useClientId();

  const isAdmin = useHasRole(["ADMIN"]);
  const isClientAdmin = useHasRole(["CLIENT_ADMIN"]);

  const { data: clientData } = useQuery<{
    client: { preferences: IClientPreferences };
  }>(QUERY_CLIENT_PREFERENCES, {
    skip: !clientId,
    variables: { id: clientId },
    context: {
      headers: {
        ClientId: clientId,
      },
    },
    fetchPolicy: "network-only",
  });

  const isFree = useCurrentHasPlan(["free"]);

  const [onUpdate] = useMutation<{
    updateCompanyPreferences: IClientPreferences;
  }>(UPDATE_CLIENT_PREFERENCES, {
    context: {
      headers: {
        ClientId: clientId,
      },
    },
    refetchQueries: [
      {
        query: QUERY_CLIENT,
        variables: {
          id: clientId,
        },
      },
    ],
  });

  const methods = useForm<FieldValues>({
    shouldFocusError: false,
    shouldUnregister: true,
  });

  const autoCheckoutEnabled = methods.watch("preferences.autoCheckout.enabled");

  useEffect(() => {
    if (clientData?.client) {
      const autoCheckout = clientData?.client?.preferences?.autoCheckout;

      methods.reset({
        preferences: {
          ...clientData?.client?.preferences,
          autoCheckout: {
            enabled: autoCheckout?.enabled || false,
            time: autoCheckout?.time
              ? dayjs()
                  .set("hour", parseInt(autoCheckout.time.split(":")[0], 10)) // Set the hour
                  .set("minute", parseInt(autoCheckout.time.split(":")[1], 10))
                  .toDate()
              : undefined,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData]);

  const onSubmit = async (variables: FieldValues) => {
    const autoCheckout = variables.preferences?.autoCheckout;

    return onUpdate({
      variables: {
        input: {
          id: clientId,
          preferences: {
            ...variables.preferences,
            autoCheckout: {
              enabled: autoCheckout?.enabled || false,
              time: autoCheckout?.time
                ? dayjs(autoCheckout.time).format("HH:mm")
                : undefined,
            },
          },
        },
      },
    })
      .then(() => {
        toast.success<string>(t("employeePreferences:toast.preferencesUpdate"));
      })
      .catch((error) => {
        toast.error<string>(
          error.message ||
            t("employeePreferences:toast.preferencesUpdateError"),
        );
      });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div>
                <h5>{t(`employeePreferences:availability.title`)}</h5>
              </div>
              <p className="text-primary mb-4">
                {t(`employeePreferences:availability.subtitle`)}
              </p>
              <PreferencesController
                name="preferences.company.employee.visitor"
                label={t(
                  `employeePreferences:input.label.preferences.visitor.enabled`,
                )}
                disabled={!(isAdmin || isClientAdmin)}
                autoDisabled={!(isAdmin || isClientAdmin)}
              />
              <PreferencesController
                name="preferences.company.employee.meetingAttendee"
                label={t(
                  `employeePreferences:input.label.preferences.meetingAttendee.enabled`,
                )}
                disabled={!(isAdmin || isClientAdmin)}
                autoDisabled={!(isAdmin || isClientAdmin)}
              />
              <PreferencesController
                name="preferences.company.employee.foodDelivery"
                label={t(
                  `employeePreferences:input.label.preferences.foodDelivery.enabled`,
                )}
                disabled={!(isAdmin || isClientAdmin)}
                autoDisabled={!(isAdmin || isClientAdmin)}
              />
              <PreferencesController
                name="preferences.company.employee.packageDelivery"
                label={t(
                  `employeePreferences:input.label.preferences.packageDelivery.enabled`,
                )}
                disabled={!(isAdmin || isClientAdmin)}
                autoDisabled={!(isAdmin || isClientAdmin)}
              />
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="mb-5">
                <div>
                  <h5>{t(`employeePreferences:accessCodeGeneration.title`)}</h5>
                </div>
                <p className="mb-2 text-primary">
                  {t(`employeePreferences:accessCodeGeneration.subtitle`)}
                </p>
                <Controller
                  name="preferences.memberAccess.employee.enabled"
                  render={({
                    field: { value, name, onChange },
                    fieldState: { error },
                  }) => (
                    <div className="form-group form-check mb-4">
                      <input
                        id={name}
                        type="checkbox"
                        className="form-check-input"
                        checked={value}
                        onChange={onChange}
                        disabled={isFree || !(isAdmin || isClientAdmin)}
                      />
                      <label
                        className="form-check-label user-select-none"
                        htmlFor={name}
                      >
                        {t(
                          `employeePreferences:input.label.preferences.access`,
                        )}
                      </label>
                      {!!error && (
                        <div className="invalid-feedback">{error.message}</div>
                      )}
                    </div>
                  )}
                />
              </div>
              <div className="mb-5">
                <h5 className="mb-3">{t(`emailPreferences:title`)}</h5>
                <div className="mb-3">
                  <h6 className="mb-2">
                    {t(`emailPreferences:subtitle.meeting`)}
                  </h6>
                  <EmailPreferencesController
                    name="preferences.email.meeting.reminder.enabled"
                    label={t(
                      `emailPreferences:input.label.preferences.email.meeting.reminder`,
                    )}
                    disabled={!(isAdmin || isClientAdmin)}
                  />
                </div>
                <div className="mb-3">
                  <h6 className="mb-2">
                    {t(`emailPreferences:subtitle.checkin`)}
                  </h6>
                  <EmailPreferencesController
                    name="preferences.email.checkin.employee.enabled"
                    label={t(
                      `emailPreferences:input.label.preferences.email.checkin.employee`,
                    )}
                    disabled={!(isAdmin || isClientAdmin)}
                  />
                  <EmailPreferencesController
                    name="preferences.email.checkin.visitor.enabled"
                    label={t(
                      `emailPreferences:input.label.preferences.email.checkin.visitor`,
                    )}
                    disabled={!(isAdmin || isClientAdmin)}
                  />
                  <EmailPreferencesController
                    name="preferences.email.checkin.meetingAttendee.enabled"
                    label={t(
                      `emailPreferences:input.label.preferences.email.checkin.meetingAttendee`,
                    )}
                    disabled={!(isAdmin || isClientAdmin)}
                  />
                </div>
                <div className="mb-3">
                  <h6 className="mb-2">
                    {t(`emailPreferences:subtitle.checkout`)}
                  </h6>
                  <EmailPreferencesController
                    name="preferences.email.checkout.employee.enabled"
                    label={t(
                      `emailPreferences:input.label.preferences.email.checkout.employee`,
                    )}
                    disabled={!(isAdmin || isClientAdmin)}
                  />
                  <EmailPreferencesController
                    name="preferences.email.checkout.visitor.enabled"
                    label={t(
                      `emailPreferences:input.label.preferences.email.checkout.visitor`,
                    )}
                    disabled={!(isAdmin || isClientAdmin)}
                  />
                  <EmailPreferencesController
                    name="preferences.email.checkout.meetingAttendee.enabled"
                    label={t(
                      `emailPreferences:input.label.preferences.email.checkout.meetingAttendee`,
                    )}
                    disabled={!(isAdmin || isClientAdmin)}
                  />
                </div>
              </div>
              <div className="mb-5">
                <div>
                  <h5>{t(`autoCheckoutPreferences:title`)}</h5>
                </div>
                <p className="mb-3 text-primary">
                  {t(`autoCheckoutPreferences:subtitle`)}
                </p>
                <Controller
                  name="preferences.autoCheckout.enabled"
                  render={({
                    field: { value, name, onChange },
                    fieldState: { error },
                  }) => (
                    <div className="form-group form-check mb-2">
                      <input
                        id={name}
                        type="checkbox"
                        className="form-check-input"
                        checked={value}
                        onChange={onChange}
                        disabled={isFree || !(isAdmin || isClientAdmin)}
                      />
                      <label
                        className="form-check-label user-select-none"
                        htmlFor={name}
                      >
                        {t(`autoCheckoutPreferences:input.label.enabled`)}
                      </label>
                      {!!error && (
                        <div className="invalid-feedback">{error.message}</div>
                      )}
                    </div>
                  )}
                />
                {autoCheckoutEnabled && (
                  <Controller
                    name="preferences.autoCheckout.time"
                    render={({
                      field: { onChange, name, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <div className="d-flex align-items-center">
                          <label
                            className="form-check-label user-select-none mr-2"
                            htmlFor={name}
                          >
                            {t(`autoCheckoutPreferences:input.label.time`)}
                          </label>
                          <div
                            style={{ width: "6rem" }}
                            className={clsx("", {
                              "is-invalid": !!error,
                            })}
                          >
                            <DatePicker
                              selected={value}
                              onChange={onChange}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              timeFormat="hh:mm aa"
                              disabled={isFree || !(isAdmin || isClientAdmin)}
                              className={clsx("form-control", {
                                "is-invalid": !!error,
                              })}
                            />
                          </div>
                          <div className="invalid-feedback">
                            {error?.message ?? "Invalid"}
                          </div>
                        </div>
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary mt-2 mb-4"
                disabled={
                  methods.formState.isSubmitting || !(isAdmin || isClientAdmin)
                }
              >
                {t("employeePreferences:button.submit")}
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
});

export default ClientPreferences;
