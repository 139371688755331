import React from "react";

import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";

import CourierDelivery from "./CourierDelivery";
import CourierDeliveries from "./CourierDeliveries";

const CourierDeliveryRouter = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <CourierDeliveries />
      </Route>
      <Route path={`${path}/new`}>
        <CourierDelivery />
      </Route>
      <Route path={`${path}/:id`}>
        <CourierDelivery />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
});

export default CourierDeliveryRouter;
